/* eslint-disable no-undef */
((document) => {
  // Select elements and initialize modal functionality
  const callNowPopUpEl = document.querySelector('.vd-call-now-popup');

  // Handle modal close: updates the cookie only when .closeBtn is clicked
  function handleModalClose(event) {
    if (event.target.classList.contains('closeBtn')) {
      // If the close button was clicked, set the cookie to "closed"
      Cookie.set('call-now-modal-popup', 'closed', 1);
    }

    // Regardless of which element was clicked, hide the modal
    callNowPopUpEl.classList.remove('modal--is-visible');
  }

  // Function to show the modal if the cookie is not set to "closed"
  function showModalIfNeeded() {
    const callNowModalPopup = Cookie.get('call-now-modal-popup');

    if (!callNowModalPopup || callNowModalPopup === 'open') {
      // Close any existing notifications and show the modal
      closeNotification();
      callNowPopUpEl.classList.add('modal--is-visible');

      // Set cookie to mark the modal as open
      Cookie.set('call-now-modal-popup', 'open', 1);

      // Add event listeners for closing the modal
      const closeButtons = document.querySelectorAll('.closeBtn, .modal__overlay');
      closeButtons.forEach((button) => {
        button.addEventListener('click', handleModalClose);
      });
    }
  }

  // Show modal after 3 seconds (30000 ms)
  setTimeout(showModalIfNeeded, 30000);
})(document);
